export function rand(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

export function clamp(num, min, max){
    return Math.min(max, Math.max(min, num));
}

export function clean(num, min, max) {
    return (clamp(num, min, max) - min) / (max - min); // 0 .. 1
}

export function round(num, amount) {
    return Math.round(num * amount) / amount; // 0 .. 1
}